<template>
    <v-container fluid>
        <v-row row wrap>
            <v-col cols="12" class="text-center" mt-4>
                <h2> All Open Orders</h2>
            </v-col>
            <v-col cols="12" mt-1>
                <v-data-table
                    :headers="headers"
                    :items="orders"
                    hide-default-footer
                    item-key="name"
                >
                <template slot="items" slot-scope="props">
                    <tr @click="props.expanded = !props.expanded">
                        <td class="text-xs-left">
                            <template v-if="props.expanded">
                                <v-icon small>star</v-icon>
                            </template>
                            <template v-else>
                                <v-icon small>arrow_forward_ios</v-icon>
                            </template>
                            
                        </td>
                        <td> {{ props.item.name }} </td>
                        <td class="text-xs-right">{{ props.item.displayStatus }}</td>
                    </tr>
                </template>
                <template slot="expand" slot-scope="props">
                    <SquareReceipt :item=props.item item-key="name" ref="receipt"
                        v-bind:order=props.item
                        v-bind:showTitle="true"
                        v-bind:showDetails="true"
                    />
                </template>
            </v-data-table>
            <div class="text-center">
                <v-btn icon color="primary" @click="refresh" :loading="loading" :disabled="loading">
                    <v-icon>refresh</v-icon>
                </v-btn>
            </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters} from 'vuex'
import SquareReceipt from '@/components/payment/SquareReceipt'
import api from '@/store/api'
export default {
    components: {
        SquareReceipt
    },
    data () {
        return {
            headers: [
                { width: '1%', align: 'left', sortable: false},
                { text: 'Order', align: 'left', sortable: true, value: 'name' },
                { text: 'Status', align: 'right', value: 'date'}
            ],
            selected: '',
        }
    },
    computed: {
        ...mapGetters ([
            'loading'
        ]),
        orders () {
            return this.$store.state.adminOpenOrders
        },
    },
    methods: {
        refresh () {
            api.retreiveAdminOpenOrders()
        }
    }
}
</script>